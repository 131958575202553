/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Container,
  DownloadIcon,
  Flex,
  Link,
  SemiTitle,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { CSVLink } from 'react-csv';
import { jsx } from 'theme-ui';
import EmbedLayout from '../../components/EmbedLayout';

import useBrellaExport from '../../useBrellaExport';
// @ts-ignore
import brellaLogo from '../../assets/brellaLogo.webp';

export default function BrellaIndexPage({ data }) {
  const { exportAsExcel: exportBrellaSponsorImport, csvData } =
    useBrellaExport(data);
  const { events } = afterQuery(data);

  return (
    <EmbedLayout sx={{ backgroundColor: 'light' }}>
      <Container
        fluid
        sx={{ paddingY: 3, maxWidth: 'container.fluid', height: '100%' }}
      >
        <Flex direction="column" gap={2}>
          <img src={brellaLogo} sx={{ width: 100 }} />
          <Title>Brella Sponsor Import</Title>
          <Text>
            Use this file to import updated sponsor data into Brella. There is a{' '}
            <Link
              href="https://help.brella.io/en/organizers/sponsors-import-export-sponsor-profiles-and-booths"
              target="_blank"
              sx={{ color: 'brand.electricBlue' }}
            >
              tutorial
            </Link>{' '}
            on Brella's site.
          </Text>
          <Flex direction="column" gap={3}>
            <Text>
              <Link
                as={CSVLink}
                variant="text"
                data={csvData}
                filename={'BrellaSponsorsImport.csv'}
                sx={{ color: 'brand.electricBlue' }}
              >
                <DownloadIcon sx={{ marginRight: 2 }} />
                <Trans>Download as CSV</Trans>
              </Link>
            </Text>
            <Flex direction="column" gap={2}>
              <SemiTitle>Events in export</SemiTitle>
              <Text>
                Multiple events can be included in a single export if they are
                hosted on this site.
              </Text>
              <Flex direction="column" gap={3}>
                {events?.map((event) => (
                  <Box>
                    <Text>{event.name}</Text>
                    <Text>
                      <Link
                        href={event.siteUrl}
                        target="_blank"
                        sx={{ color: 'brand.electricBlue' }}
                      >
                        {event.siteUrl}
                      </Link>
                    </Text>
                    <Text>
                      <Link
                        href={`https://app.bottlebooks.me/dashboard/app/events/${event.eventId}`}
                        target="_blank"
                        sx={{ color: 'brand.electricBlue' }}
                      >
                        Manage on Bottlebooks
                      </Link>
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Flex>
            {/* <Text>
            <Link onClick={exportBrellaSponsorImport}>
              <DownloadIcon sx={{ marginRight: 2 }} />
              <Trans>Download as Excel</Trans>
            </Link>
          </Text> */}
          </Flex>
        </Flex>
      </Container>
    </EmbedLayout>
  );
}

export const pageQuery = graphql`
  query BrellaAdminPage {
    ...BrellaExport
  }
`;

function afterQuery({ allEvent }) {
  return {
    events: [...allEvent?.nodes].sort((a, b) =>
      a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
    ),
  };
}
