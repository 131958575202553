import * as XLSX from 'xlsx/xlsx.mjs';
import { graphql } from 'gatsby';

export default function useBrellaExport({ allExhibitor, allEvent }) {
  const sponsorData = afterQuery({ allExhibitor, allEvent });
  const formattedData = getData(sponsorData);
  return {
    exportAsExcel: () => exportAsExcel(formattedData),
    csvData: formattedData,
  };
}

function getData({ exhibitors }) {
  const header = [
    'Id',
    'External Id',
    'Name',
    'Category',
    'Subtitle',
    'Enable Chat',
    'Website',
    'Facebook',
    'Twitter',
    'Linkedin',
    'Content',
    'Logo',
    'Media type',
    'Media title',
    'Media URL',
  ];

  const data = exhibitors?.map(
    ({
      exhibitorId,
      name,
      website,
      facebook,
      twitter,
      linkedIn,
      shortDescription,
      description,
      logoUrl,
      event,
    }) => {
      const companyRow = [
        '',
        exhibitorId,
        name,
        'Winery',
        '',
        'Yes',
        website,
        facebook,
        twitter,
        '',
        shortDescription || description,
        logoUrl,
        '', // Media type
        '', // Media title
        '', // Media URL
      ];
      const carousel = [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Carousel', // Media type
        'Profile image', // Media title
        `${event.siteUrl}/brella/exhibitors/${exhibitorId}`, // Media URL
      ];
      const sidebar = [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Sidebar', // Media type
        'Products', // Media title
        `${event.siteUrl}/brella/exhibitors/${exhibitorId}/sidebar`, // Media URL
      ];
      return [companyRow, carousel, sidebar];
    }
  );
  return [header, ...data.flat()];
}

function exportAsExcel(data) {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  const columnWidths = [
    30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30,
  ];
  var wscols = columnWidths.map((width) => {
    return {
      wch: width,
    };
  });

  ws['!cols'] = wscols;
  XLSX.utils.book_append_sheet(wb, ws, 'Brella sponsor import');
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, 'Brella sponsors.xlsx');
}

export const pageQuery = graphql`
  fragment BrellaExport on Query {
    allExhibitor {
      nodes {
        exhibitorId
        name
        website
        twitter
        facebook
        shortDescription
        description
        logoUrl
        eventId
      }
    }
    allEvent {
      nodes {
        eventId
        name
        siteUrl
      }
    }
  }
`;

function afterQuery({ allExhibitor, allEvent }) {
  const eventMap = allEvent?.nodes?.reduce((acc, event) => {
    acc[event.eventId] = event;
    return acc;
  }, {});
  const enhancedExhibitors = allExhibitor?.nodes?.map((exhibitor) => {
    return {
      ...exhibitor,
      event: eventMap[exhibitor.eventId],
    };
  });
  const sortedExhibitors = [...enhancedExhibitors]?.sort((a, b) =>
    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
  );
  return {
    events: allEvent?.nodes,
    exhibitors: sortedExhibitors,
  };
}
