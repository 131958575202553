/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { Box } from "@bottlebooks/gatsby-theme-base";
import useEventTheme from "@bottlebooks/gatsby-theme-event/src/components/Layout/useEventTheme";
import { jsx, ThemeProvider } from "theme-ui";

export default function EmbedLayout({ children, ...rest }) {
  const theme = useEventTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "100vh" }} {...rest}>
        {children}
      </Box>
    </ThemeProvider>
  );
}
